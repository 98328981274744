export default class FormularioFormatado {
    constructor({ formQuestionario, perguntasForm, clientesSelecionados, perguntasFormClone, perguntasRemovidasIds }) {
        this.id = formQuestionario.id;
        this.nome = formQuestionario.nome;
        this.tipo = formQuestionario.tipo;
        this.todosClientesMarcados = formQuestionario.todosClientesMarcados;
        this.clientes = clientesSelecionados?.map(({ id }) => {
            return { id };
        });
        this.anexoObrigatorio = formQuestionario.inserirAnexo ? formQuestionario.anexoObrigatorio : false;
        this.inserirAnexo = formQuestionario.inserirAnexo;
        this.ativo = formQuestionario.ativo;
        const { perguntasNovas, perguntasAlteradas } = this.mapearPerguntas(perguntasForm, perguntasFormClone, formQuestionario);
        this.perguntasNovas = perguntasNovas;
        this.perguntasAlteradas = perguntasAlteradas;
        this.perguntasRemovidasIds = perguntasRemovidasIds;
    }

    mapearPerguntas(perguntasForm, perguntasFormClone) {
        const perguntasNovas = [];
        const perguntasAlteradas = [];

        perguntasForm.forEach((perguntaAtual, index) => {
            const perguntaClone = perguntasFormClone.find((p) => p.questionarioSesmtPerguntaId === perguntaAtual.questionarioSesmtPerguntaId);

            if (!perguntaClone) {
                perguntasNovas.push(this.formatarPergunta(perguntaAtual, index));
            } else if (
                perguntaAtual.obrigatorio !== perguntaClone.obrigatorio ||
                perguntaAtual.perguntaId !== perguntaClone.perguntaId ||
                JSON.stringify(perguntaAtual.opcoesRespostas) !== JSON.stringify(perguntaClone.opcoesRespostas)
            ) {
                perguntasAlteradas.push(this.formatarPergunta(perguntaAtual, index));
            }
        });

        return { perguntasNovas, perguntasAlteradas };
    }

    formatarPergunta(pergunta, ordem) {
        return {
            questionarioSesmtPerguntaId: pergunta.questionarioSesmtPerguntaId,
            perguntaId: pergunta.perguntaId,
            ordem: ordem + 1,
            obrigatorio: pergunta.obrigatorio,
            opcoesRespostas: this.formatarOpcoesRespostas(pergunta.opcoesRespostas || [])
        };
    }

    formatarOpcoesRespostas(opcoesRespostas) {
        return opcoesRespostas.map((opcao) => ({
            resposta: opcao.resposta,
            contemCondicional: opcao.perguntasCondicionais?.some((condicional) => condicional.perguntaId) || false,
            perguntasCondicionais: opcao.perguntasCondicionais?.map((condicional, index) => this.formatarPergunta(condicional, index)) || []
        }));
    }
}
