export default class FormularioEdicaoFormatado {
    static formatar(perguntasForm) {
        return perguntasForm.map((pergunta) => FormularioEdicaoFormatado.formatarPergunta(pergunta));
    }

    static formatarPergunta(pergunta) {
        return {
            questionarioSesmtPerguntaOrigemId: pergunta.questionarioSesmtPerguntaOrigemId,
            questionarioSesmtPerguntaId: pergunta.questionarioSesmtPerguntaId,
            perguntaId: pergunta.perguntaId,
            descricao: pergunta.perguntaSesmt.descricao,
            tipo: pergunta.perguntaSesmt.tipo,
            opcoes: pergunta.perguntaSesmt.opcoes,
            ordem: pergunta.ordem,
            obrigatorio: pergunta.obrigatorio,
            nivel: pergunta.nivel,
            opcoesRespostas: FormularioEdicaoFormatado.formatarOpcoesRespostas(pergunta.opcoesRespostas || [])
        };
    }

    static formatarOpcoesRespostas(opcoesRespostas) {
        return opcoesRespostas.map((opcao) => ({
            resposta: opcao.resposta,
            contemCondicional: opcao.contemCondicional,
            perguntasCondicionais:
                opcao.perguntasCondicionais?.map((condicional, index) => FormularioEdicaoFormatado.formatarPergunta(condicional, index)) || []
        }));
    }
}
