<script setup>
import GerenciadorPerguntas from '@/pages/questionarios-sesmt/GerenciadorPerguntas.vue';
import { defineProps, defineEmits, computed } from 'vue';

const emit = defineEmits(['update:form', 'update:perguntasForm']);
const props = defineProps({
    perguntas: {
        type: Array,
        required: true
    },
    perguntasForm: {
        type: Array,
        required: true
    },
    submitted: {
        type: Boolean,
        required: true
    }
});
const _perguntasForm = computed({
    get: () => props.perguntasForm,
    set: (value) => emit('update:perguntasForm', value)
});
</script>
<template>
    <GerenciadorPerguntas
        v-model:perguntas-form="_perguntasForm"
        :contexto-geral="_perguntasForm"
        :perguntas="perguntas"
        :submitted="submitted"
        pode-add-pergunta
    />
</template>
