<script setup>
import { defineProps, defineEmits, computed, nextTick, ref, inject } from 'vue';
import ComponentesPerguntas from './ComponentesPerguntas.vue';
import { useConfirm } from 'primevue/useconfirm';
import { v4 as uuidv4 } from 'uuid';

const emit = defineEmits(['removerPerguntaCondicional', 'update:perguntaCondicional']);
const props = defineProps({
    perguntaCondicional: {
        type: Object,
        required: true
    },
    indexPerguntaCondicional: {
        type: Number,
        required: true
    },
    indexOpcaoResposta: {
        type: Number
    },
    perguntas: {
        type: Array,
        required: true
    },
    contextoGeral: {
        type: Array,
        required: true
    },
    ordemPai: {
        type: String
    },
    alternativaPai: {
        type: String
    }
});

const uniqueId = `${uuidv4()}`;

const _perguntaCondicionalAtual = computed({
    get: () => props.perguntaCondicional,
    set: (value) => {
        emit('update:perguntaCondicional', value);
    }
});

const contemCondicional = ref(false);

const alternativaTemCondicinal = computed(() => _perguntaCondicionalAtual.value?.opcoesRespostas?.some((opcao) => opcao.contemCondicional));

if (alternativaTemCondicinal.value) {
    contemCondicional.value = true;
}

function isPerguntaUtilizada(id) {
    return verificarPerguntaUtilizadaEmTodoContexto(props.contextoGeral, id);
}

function verificarPerguntaUtilizadaEmTodoContexto(perguntas, id) {
    for (const pergunta of perguntas) {
        if (pergunta.perguntaId === id) return true;
    }
    return false;
}

function obterComponenteOpcao(perguntaAtual) {
    switch (perguntaAtual.tipo) {
        case 'CB':
            return 'RadioButton';
        case 'ME':
            return 'Checkbox';
        default:
            return undefined;
    }
}

function getOrdemPerguntaCondicionalNivelada(indexOpcaoAtual) {
    return `${props.ordemPai ? props.ordemPai : props.indexPerguntaCondicional + 1}. ${indexOpcaoAtual + 1}`;
}

function removerPergunta() {
    confirm.require({
        group: uniqueId,
        message: 'Tem certeza de que deseja excluir esta pergunta condicional?',
        header: 'Excluir condicional',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        rejectClass: 'p-button-outlined',
        accept() {
            emit('removerPerguntaCondicional', {
                indexPerguntaCondicional: props.indexPerguntaCondicional,
                indexOpcaoResposta: props.indexOpcaoResposta
            });
        }
    });
}

const confirm = useConfirm();

async function atualizarPerguntaCondicionalAtual(idPergunta) {
    if (isPerguntaUtilizada(idPergunta)) return;
    const perguntaAtualContemCondicional = _perguntaCondicionalAtual.value?.opcoesRespostas?.some((opcao) => opcao?.contemCondicional);
    await nextTick();
    if (perguntaAtualContemCondicional) {
        confirm.require({
            group: `atualizarPerguntaCondicionalAtual${uniqueId}`,
            message: 'Todas as perguntas condicionais serão removidas também. Esta ação não poderá ser desfeita.',
            header: 'Essa pergunta possui perguntas condicionais.',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            rejectClass: 'p-button-outlined',
            accept() {
                aplicarMudancaDePergunta(idPergunta);
            }
        });
    } else {
        aplicarMudancaDePergunta(idPergunta);
    }
}

async function aplicarMudancaDePergunta(idPergunta) {
    const pergunta = {
        ...obterPerguntaPorId(idPergunta)
    };

    _perguntaCondicionalAtual.value = {
        ..._perguntaCondicionalAtual.value,
        perguntaId: pergunta.id,
        opcoes: pergunta.opcoes,
        tipo: pergunta.tipo,
        obrigatorio: false,
        opcoesRespostas: []
    };
    await nextTick();

    if (!_perguntaCondicionalAtual.value?.opcoes?.length) return;
    if (!_perguntaCondicionalAtual.value?.opcoesRespostas?.length) {
        _perguntaCondicionalAtual.value.opcoesRespostas = [];
    }

    for (const opcao of pergunta.opcoes) {
        _perguntaCondicionalAtual.value.opcoesRespostas.push({
            resposta: opcao,
            contemCondicional: false,
            perguntasCondicionais: []
        });
    }
}

function obterPerguntaPorId(id) {
    return props.perguntas.find((pergunta) => pergunta.id === id);
}

const perguntasRemovidasIds = inject('perguntasRemovidasIds');

function removerPerguntaCondicional(indexOpcao) {
    if (
        _perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].perguntasCondicionais[props.indexPerguntaCondicional]?.questionarioSesmtPerguntaId
    ) {
        perguntasRemovidasIds.push(
            _perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].perguntasCondicionais[props.indexPerguntaCondicional]
                .questionarioSesmtPerguntaId
        );
    }
    _perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].perguntasCondicionais.splice(props.indexPerguntaCondicional, 1);
    if (!_perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].perguntasCondicionais.length) {
        _perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].contemCondicional = false;
    }
}

function addPerguntaCondicionalFilha(newValue, indexOpcao, indexPerguntaCondicional) {
    _perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].perguntasCondicionais[indexPerguntaCondicional] = newValue;
}

async function addCondicionalNaAlternativa(indexOpcao) {
    _perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].perguntasCondicionais.push({ perguntaId: null, descricao: '' });
    _perguntaCondicionalAtual.value.opcoesRespostas[indexOpcao].contemCondicional = true;
}

const dragging = ref(-1);

function dragStart(index, ev) {
    ev.dataTransfer.effectAllowed = 'move';
    ev.dataTransfer.setData('index', index);
    dragging.value = index;
}

function dragEnd() {
    dragging.value = -1;
}

function dragFinish(index, ev, list) {
    const fromIndex = ev.dataTransfer.getData('index');
    if (fromIndex !== index) {
        moveItem(fromIndex, index, list);
    }
    ev.target.style.marginTop = '2px';
    ev.target.style.marginBottom = '2px';
    dragging.value = -1;
}

function moveItem(from, to, list) {
    if (from < 0 || from >= list.length || to < 0 || to >= list.length) {
        return;
    }

    const item = list.splice(from, 1)[0];
    list.splice(to, 0, item);
}

const loadPerguntas = inject('loadPerguntas');

function carregarPerguntas(event) {
    loadPerguntas(event);
}
</script>

<template>
    <div class="border-300 border-round surface-50">
        <div v-if="alternativaPai" class="text-500 surface-200 p-2 border-round font-semibold">
            Condicional {{ indexPerguntaCondicional + 1 }} : {{ alternativaPai }}
        </div>
        <div class="mb-3 p-5">
            <div class="mb-3">
                <div class="flex flex-row justify-content-between">
                    <div v-if="props.ordemPai">{{ props.ordemPai }}</div>
                    <div v-else>{{ props.indexPerguntaCondicional + 1 }}</div>
                    <div class="flex flex-row align-items-center">
                        <div class="field-checkbox m-3 flex align-items-center gap-2">
                            <Checkbox :value="_perguntaCondicionalAtual.obrigatorio" v-model="_perguntaCondicionalAtual.obrigatorio" :binary="true" />
                            <span>Obrigatório</span>
                        </div>
                        <div v-if="_perguntaCondicionalAtual.opcoesRespostas?.length" class="field-checkbox m-3 flex align-items-center gap-2">
                            <InputSwitch id="ativo" :disabled="alternativaTemCondicinal" v-model="contemCondicional" />
                            <span>Perguntas Condicionais</span>
                        </div>
                        <Button icon="pi pi-trash" class="ml-auto p-button-rounded p-button-text p-button-plain" @click="removerPergunta()" />
                    </div>
                </div>
                <div class="mt-2 flex align-items-center">
                    <div :style="{ width: '50%' }">
                        <Dropdown
                            id="perguntaCondicional"
                            class="selects"
                            :model-value="_perguntaCondicionalAtual.perguntaId"
                            :options="props.perguntas"
                            :filter="true"
                            @filter="carregarPerguntas($event)"
                            optionValue="id"
                            optionLabel="pergunta"
                            placeholder="Selecione..."
                            @update:model-value="atualizarPerguntaCondicionalAtual($event)"
                        >
                            <template #option="slotProps">
                                <div
                                    :style="{
                                        opacity: isPerguntaUtilizada(slotProps.option.id) ? 0.3 : 1
                                    }"
                                >
                                    {{ slotProps.option.pergunta }}
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div v-if="_perguntaCondicionalAtual" class="flex flex-column gap-2">
                <div
                    v-for="(opcao, indexOpcao) of _perguntaCondicionalAtual?.opcoesRespostas"
                    :key="indexOpcao"
                    class="flex flex-row align-items-center justify-content-between"
                >
                    <div class="flex align-items-center gap-3 col-5">
                        <component :is="obterComponenteOpcao(_perguntaCondicionalAtual)" disabled />
                        <span class="text-500">{{ opcao.resposta }}</span>
                        <span v-if="opcao.contemCondicional" class="p-2 font-bold" style="background-color: #c3f3f6">CONDICIONAL</span>
                    </div>
                    <div class="flex align-items-center">
                        <Button
                            v-if="contemCondicional"
                            label="Pergunta condicional"
                            class="p-button-text"
                            @click="addCondicionalNaAlternativa(indexOpcao)"
                        />
                    </div>
                </div>
                <div v-if="_perguntaCondicionalAtual?.outros" class="flex flex-row align-items-center">
                    <div class="col-5 flex align-items-center gap-3">
                        <component :is="obterComponenteOpcao(_perguntaCondicionalAtual)" disabled />
                        <span class="text-500">Outros</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="_perguntaCondicionalAtual?.opcoesRespostas?.length" class="mt-3">
        <div v-for="(opcao, indexOpcao) of _perguntaCondicionalAtual?.opcoesRespostas" :key="indexOpcao">
            <div v-for="(perguntaCondicionalFilha, indexPerguntaCondicional) in opcao.perguntasCondicionais" :key="indexPerguntaCondicional">
                <div
                    :draggable="true"
                    @dragstart="(event) => dragStart(indexPerguntaCondicional, event)"
                    @dragover.prevent
                    @dragend="dragEnd"
                    @drop="(event) => dragFinish(indexPerguntaCondicional, event, opcao.perguntasCondicionais)"
                >
                    <ComponentesPerguntas
                        :perguntaCondicional="perguntaCondicionalFilha"
                        :indexPerguntaCondicional="indexPerguntaCondicional"
                        :perguntas="props.perguntas"
                        :contextoGeral="opcao.perguntasCondicionais"
                        @removerPerguntaCondicional="removerPerguntaCondicional(indexOpcao)"
                        @update:perguntaCondicional="addPerguntaCondicionalFilha($event, indexOpcao, indexPerguntaCondicional)"
                        :ordemPai="getOrdemPerguntaCondicionalNivelada(indexOpcao)"
                        :alternativaPai="opcao.resposta"
                    />
                </div>
            </div>
        </div>
    </div>

    <ConfirmDialog :closable="false" :draggable="false" :group="uniqueId" />
    <ConfirmDialog :closable="false" :draggable="false" :group="`atualizarPerguntaCondicionalAtual${uniqueId}`" />
</template>
