<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';

const emit = defineEmits(['update:form', 'update:clientesSelecionados', 'onLoadCustomers']);
const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    submitted: {
        type: Boolean,
        required: true
    },
    clientes: {
        type: Array,
        required: true
    },
    clientesSelecionados: {
        type: Array,
        required: true
    }
});
const _form = computed({
    get: () => props.form,
    set: (value) => emit('update:form', value)
});
const _clientesSelecionados = computed({
    get: () => props.clientesSelecionados,
    set: (value) => emit('update:clientesSelecionados', value)
});
const tipos = ref([
    {
        value: 'IN',
        label: 'Inicial'
    },
    {
        value: 'AM',
        label: 'Ambiente'
    }
    // Removido temporariamente pois ainda não foi desenvolvida essa parte
    // {
    //     value: 'IF',
    //     label: 'Identificação e caracterização de perigo/fator de risco'
    // }
]);
const removerCliente = (index) => {
    _clientesSelecionados.value.splice(index, 1);
};
</script>
<template>
    <div class="flex align-items-center my-3">
        <InputSwitch id="ativo" v-model="_form.ativo" />
        <label for="ativo" class="ml-2">Ativo</label>
    </div>
    <div class="flex flex-row gap-3 my-3">
        <div class="field" style="width: 40%">
            <label for="name">Nome do questionário</label>
            <InputText
                v-model.trim="_form.nome"
                required="true"
                autofocus
                autocomplete="off"
                placeholder="Informe o nome"
                :class="{ 'p-invalid': props.submitted && !_form.nome }"
            />
            <small class="p-error" v-if="props.submitted && !_form.nome">Nome é obrigatório.</small>
        </div>
        <div class="field" style="width: 30%">
            <label for="tipo">Tipo</label>
            <Dropdown
                id="tipo"
                class="selects"
                v-model="_form.tipo"
                :options="tipos"
                required="true"
                optionValue="value"
                optionLabel="label"
                placeholder="Selecione..."
                :class="{ 'p-invalid': props.submitted && !_form.tipo }"
            />
            <small class="p-error" v-if="props.submitted && !_form.tipo">Tipo é obrigatório.</small>
        </div>
    </div>
    <div class="my-3">
        <div class="flex align-items-center my-3">
            <InputSwitch id="ativo" v-model="_form.todosClientesMarcados" />
            <label for="ativo" class="ml-2">Aplicar para todos os clientes</label>
        </div>
        <div v-if="!_form.todosClientesMarcados">
            <div class="field" style="width: 40%">
                <label for="name">Selecione os clientes*</label>
                <MultiSelect
                    id="customers"
                    placeholder="Selecione..."
                    :filter="true"
                    @filter="$emit('onLoadCustomers', $event)"
                    v-model="_clientesSelecionados"
                    :maxSelectedLabels="10"
                    :options="clientes"
                    optionLabel="name"
                    dataKey="id"
                    class="p-1"
                />
            </div>
            <div class="my-5">
                <DataTable :value="_clientesSelecionados" :scrollable="true" scrollHeight="250px">
                    <template #empty> Nenhum cliente encontrado. </template>
                    <Column field="name" header="Cliente" sortable />
                    <Column bodyClass="text-right" headerStyle="width: 10rem;">
                        <template #body="{ index }">
                            <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="removerCliente(index)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <div class="flex flex-row gap-3 mt-4">
        <div class="field-checkbox">
            <Checkbox v-model="_form.inserirAnexo" inputId="inserirAnexo" :binary="true" />
            <label for="inserirAnexo">Permite inserir anexo</label>
        </div>
        <div class="field-checkbox">
            <Checkbox
                id="anexoObrigatorio"
                :disabled="!_form.inserirAnexo"
                :value="_form.anexoObrigatorio"
                v-model="_form.anexoObrigatorio"
                :binary="true"
            />
            <label :class="{ 'text-500': !_form.inserirAnexo }" for="anexoObrigatorio">Obrigatório</label>
        </div>
    </div>
</template>
