<script setup>
import { ref, defineProps, defineEmits, computed, inject } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import EnumTipoPergunta from '../../enums/EnumTipoPergunta.js';
import DialogPerguntasCondicionais from './AbasCadastro/components/DialogPerguntasCondicionais.vue';

const props = defineProps({
    submitted: {
        type: Boolean,
        required: true
    },
    perguntas: {
        type: Array,
        required: true
    },
    contextoGeral: {
        type: Array,
        required: true
    },
    perguntasForm: {
        type: Array,
        required: true
    },
    podeAddPergunta: {
        type: Boolean,
        default: false
    },
    numeroPerguntaPai: {
        type: String,
        default: null
    }
});

const dialogPerguntasCondicionais = ref();

const emit = defineEmits(['update:form', 'update:perguntasForm']);

const _perguntasForm = computed({
    get: () => props.perguntasForm,
    set: (value) => emit('update:perguntasForm', value)
});

const dragging = ref(-1);

function dragStart(index, ev) {
    ev.dataTransfer.effectAllowed = 'move';
    ev.dataTransfer.setData('index', index);
    dragging.value = index;
}

function dragEnd() {
    dragging.value = -1;
}

function dragFinish(index, ev) {
    const fromIndex = ev.dataTransfer.getData('index');
    if (fromIndex !== index) {
        moveItem(fromIndex, index);
    }
    ev.target.style.marginTop = '2px';
    ev.target.style.marginBottom = '2px';
    dragging.value = -1;
}

function moveItem(from, to) {
    if (from < 0 || from >= _perguntasForm.value.length || to < 0 || to >= _perguntasForm.value.length) {
        return;
    }

    const item = _perguntasForm.value.splice(from, 1)[0];
    _perguntasForm.value.splice(to, 0, item);
}

function onAddPergunta(idPergunta, index) {
    if (_perguntasForm.value[index].opcoesRespostas?.some((op) => op.contemCondicional)) {
        confirm.require({
            group: `${index}`,
            message: 'Todas as perguntas condicionais serão removidas também. Esta ação não poderá ser desfeita.',
            header: 'Essa pergunta possui perguntas condicionais.',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            rejectClass: 'p-button-outlined',
            accept() {
                applyPerguntaChange(idPergunta, index);
            }
        });
    } else {
        applyPerguntaChange(idPergunta, index);
    }
}

function applyPerguntaChange(idPergunta, index) {
    if (isPerguntaUtilizada(idPergunta)) return;

    const pergunta = {
        ...obterPerguntaPorId(idPergunta)
    };
    _perguntasForm.value[index] = {
        ..._perguntasForm.value[index],
        perguntaId: pergunta.id,
        opcoes: pergunta.opcoes,
        descricao: pergunta.descricao,
        tipo: pergunta.tipo,
        obrigatorio: false,
        outros: pergunta.outros,
        opcoesRespostas: []
    };
}

function isPerguntaUtilizada(id) {
    return verificarPerguntaUtilizadaEmTodoContexto(props.contextoGeral, id);
}

function verificarPerguntaUtilizadaEmTodoContexto(perguntas, id) {
    for (const pergunta of perguntas) {
        if (pergunta.perguntaId === id) return true;
    }
    return false;
}

const confirm = useConfirm();

const perguntasRemovidasIds = inject('perguntasRemovidasIds');

function removerPergunta(index) {
    const getMensagem = () => {
        if (_perguntasForm.value[index].opcoesRespostas?.some((op) => op.contemCondicional)) {
            return 'Essa pergunta possui perguntas condicionais. Todas as perguntas condicionais serão removidas também. Esta ação não poderá ser desfeita.';
        }
        return 'Esta ação não poderá ser desfeita.';
    };

    confirm.require({
        group: `${index}`,
        header: 'Você tem certeza que deseja remover esta pergunta?',
        acceptLabel: 'Confirmar',
        rejectLabel: 'Cancelar',
        rejectClass: 'p-button-outlined',
        message: getMensagem(),
        accept() {
            removeItemAt(index);
        }
    });
}

function removeItemAt(index) {
    if (_perguntasForm.value[index]?.questionarioSesmtPerguntaId) {
        perguntasRemovidasIds.push(_perguntasForm.value[index].questionarioSesmtPerguntaId);
    }
    _perguntasForm.value.splice(index, 1);
}

function obterPerguntaPorId(id) {
    return props.perguntas.find((pergunta) => pergunta.id === id);
}

function obterComponenteOpcao(pergunta) {
    switch (pergunta.tipo) {
        case 'CB':
            return 'RadioButton';
        case 'ME':
            return 'Checkbox';
        default:
            return undefined;
    }
}

function atualizarPergunta(pergunta, index) {
    _perguntasForm.value[index] = pergunta;
}

const loadPerguntas = inject('loadPerguntas');

function carregarPerguntas(event) {
    loadPerguntas(event);
}

const indexAtivo = ref(0);

function addPergunta() {
    _perguntasForm.value.push({ perguntaId: null, descricao: '' });
    indexAtivo.value = _perguntasForm.value.length - 1;
}
</script>
<template>
    <div class="grid">
        <div class="mt-5 mb-3">
            <h3 class="m-0">Questionário</h3>
        </div>
        <Accordion class="w-full mb-5" :activeIndex="indexAtivo">
            <AccordionTab
                v-for="(perguntaForm, index) in _perguntasForm"
                :key="index"
                :pt="{
                    headerIcon: { style: ['display:none'] },
                    headerAction: {
                        style: ['background-color:#FFFFFF; height: 60px']
                    }
                }"
            >
                <template #header>
                    <div
                        class="flex flex-row w-full align-items-center h-9 draggable-content"
                        :draggable="true"
                        @dragstart="(event) => dragStart(index, event)"
                        @dragover.prevent
                        @dragend="dragEnd"
                        @drop="(event) => dragFinish(index, event)"
                    >
                        <div class="w-9">
                            <i class="pi pi-bars mr-3" />
                            <span>{{ _perguntasForm[index]?.descricao?.length ? _perguntasForm[index]?.descricao : 'Nova pergunta' }}</span>
                        </div>
                        <div class="w-1 flex flex-row align-items-center">
                            <div class="ml-auto flex gap-2 mr-3">
                                <i v-if="perguntaForm.obrigatorio" class="pi pi-check-circle" />
                                <i v-if="perguntaForm.opcoesRespostas?.some((op) => op.contemCondicional)" class="pi pi-sitemap" />
                            </div>
                        </div>
                        <div class="w-2 flex flex-row align-items-center">
                            <div class="separador" />
                            <span class="ml-3">{{
                                _perguntasForm[index].tipo
                                    ? EnumTipoPergunta.find((tipo) => tipo.value === _perguntasForm[index].tipo)?.label
                                    : 'Tipo Pergunta'
                            }}</span>
                            <Button
                                icon="pi pi-trash"
                                class="ml-auto p-button-rounded p-button-text p-button-plain"
                                @click.stop="removerPergunta(index)"
                            />
                            <ConfirmDialog :closable="false" :draggable="false" :group="`${index}`" />
                        </div>
                    </div>
                </template>
                <div class="p-3">
                    <div class="mt-2 flex align-items-center mb-5">
                        <div :style="{ width: '50%' }">
                            <Dropdown
                                id="pergunta"
                                class="selects"
                                :model-value="_perguntasForm[index].perguntaId"
                                :options="perguntas"
                                :filter="true"
                                @filter="carregarPerguntas($event)"
                                optionValue="id"
                                optionLabel="pergunta"
                                placeholder="Selecione..."
                                :class="{ 'p-invalid': submitted && !perguntaForm.perguntaId }"
                                @update:model-value="onAddPergunta($event, index)"
                            >
                                <template #option="slotProps">
                                    <div
                                        :style="{
                                            opacity: isPerguntaUtilizada(slotProps.option.id) ? 0.3 : 1
                                        }"
                                    >
                                        {{ slotProps.option.pergunta }}
                                    </div>
                                </template>
                            </Dropdown>
                            <small class="p-error" v-if="submitted && !perguntaForm.perguntaId">Pergunta é obrigatória.</small>
                        </div>
                    </div>
                    <div v-if="_perguntasForm[index]" class="flex flex-column">
                        <div v-for="(opcao, indexOpcao) of _perguntasForm[index]?.opcoes" :key="indexOpcao" class="grid align-items-center">
                            <div class="flex align-items-center gap-3 col-5">
                                <component :is="obterComponenteOpcao(_perguntasForm[index])" disabled />
                                <InputText :model-value="opcao" disabled />
                            </div>
                        </div>
                        <div v-if="_perguntasForm[index]?.outros" class="grid align-items-center">
                            <div class="flex align-items-center gap-3 col-5">
                                <component :is="obterComponenteOpcao(_perguntasForm[index])" disabled />
                                <InputText model-value="Outros" disabled />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row justify-content-between">
                        <div class="field-checkbox m-3 flex align-items-center gap-2">
                            <Checkbox :value="perguntaForm.obrigatorio" v-model="perguntaForm.obrigatorio" :binary="true" />
                            <span>Obrigatório</span>
                        </div>
                        <div>
                            <Button
                                v-if="_perguntasForm[index]?.opcoes?.length"
                                label="Perguntas Condicionais"
                                class="p-button-outlined text-lg ml-auto"
                                @click="() => dialogPerguntasCondicionais.openDialog(_perguntasForm[index])"
                            />
                            <DialogPerguntasCondicionais
                                ref="dialogPerguntasCondicionais"
                                :contextoGeral="props.contextoGeral"
                                :perguntas="props.perguntas"
                                @update:pergunta="(event) => atualizarPergunta(event, index)"
                            />
                        </div>
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
        <Button label="Nova pergunta" class="p-button-outlined font-semibold text-lg w-10rem" @click="addPergunta" />
    </div>
</template>
<style scoped lang="scss">
.draggable-content {
    &:hover {
        cursor: grab;
    }
    &:active {
        cursor: grabbing;
    }
}
.selects {
    width: 100%;
    border-radius: 6px;
}
.separador {
    width: 1px;
    height: 60px;
    background-color: var(--surface-d);
}
</style>
