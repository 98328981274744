<script setup>
import { ref, defineExpose, defineProps, defineEmits, computed, inject } from 'vue';
import ComponentesPerguntas from './ComponentesPerguntas.vue';

const emit = defineEmits(['update:form', 'update:pergunta']);
const props = defineProps({
    perguntas: {
        type: Array,
        required: true
    }
});

const pergunta = ref({});

const _perguntaPrincipal = computed({
    get: () => pergunta.value,
    set: (value) => {
        pergunta.value = value;
        emit('update:pergunta', value);
    }
});

const open = ref(false);

async function openDialog(payload) {
    pergunta.value = payload;
    addPerguntaCondicionalNaAlternativa();
    open.value = true;
}

function addPerguntaCondicionalNaAlternativa() {
    if (_perguntaPrincipal.value?.opcoesRespostas?.length) return;
    _perguntaPrincipal.value.opcoesRespostas = [];
    for (const opcao of _perguntaPrincipal.value.opcoes) {
        _perguntaPrincipal.value.opcoesRespostas.push({
            resposta: opcao,
            contemCondicional: true,
            perguntasCondicionais: [{ perguntaId: null }]
        });
    }
}

function closeDialog() {
    open.value = false;
}

function obterComponenteOpcao(perguntaAtual) {
    switch (perguntaAtual.tipo) {
        case 'CB':
            return 'RadioButton';
        case 'ME':
            return 'Checkbox';
        default:
            return undefined;
    }
}

const perguntasRemovidasIds = inject('perguntasRemovidasIds');

function removerPerguntaCondicional(indices) {
    const { indexPerguntaCondicional, indexOpcaoResposta } = indices;
    if (_perguntaPrincipal.value.opcoesRespostas[indexOpcaoResposta]?.perguntasCondicionais[indexPerguntaCondicional]?.questionarioSesmtPerguntaId) {
        perguntasRemovidasIds.push(
            _perguntaPrincipal.value.opcoesRespostas[indexOpcaoResposta].perguntasCondicionais[indexPerguntaCondicional].questionarioSesmtPerguntaId
        );
    }
    _perguntaPrincipal.value.opcoesRespostas[indexOpcaoResposta].perguntasCondicionais.splice(indexPerguntaCondicional, 1);
    if (!_perguntaPrincipal.value.opcoesRespostas[indexOpcaoResposta].perguntasCondicionais.length) {
        _perguntaPrincipal.value.opcoesRespostas[indexOpcaoResposta].contemCondicional = false;
    }
}

async function addCondicionalNaAlternativa(indexOpcaoResposta) {
    _perguntaPrincipal.value.opcoesRespostas[indexOpcaoResposta].perguntasCondicionais.push({ perguntaId: null, descricao: '' });
    _perguntaPrincipal.value.opcoesRespostas[indexOpcaoResposta].contemCondicional = true;
}

const dragging = ref(-1);
const scrollContainer = ref(null);
let autoScrollInterval = null;

function dragStart(index, ev) {
    ev.dataTransfer.effectAllowed = 'move';
    ev.dataTransfer.setData('index', index);
    dragging.value = index;
}

function dragEnd() {
    dragging.value = -1;

    clearInterval(autoScrollInterval);
}

function dragFinish(index, ev, list) {
    const fromIndex = ev.dataTransfer.getData('index');
    if (fromIndex !== index) {
        moveItem(fromIndex, index, list);
    }
    ev.target.style.marginTop = '2px';
    ev.target.style.marginBottom = '2px';
    dragging.value = -1;

    clearInterval(autoScrollInterval);
}

function moveItem(from, to, list) {
    if (from < 0 || from >= list.length || to < 0 || to >= list.length) {
        return;
    }

    const item = list.splice(from, 1)[0];
    list.splice(to, 0, item);
}

function handleAutoScroll(event) {
    const dialogContent = document.querySelector('.p-dialog-content');
    const { top, bottom } = dialogContent.getBoundingClientRect();
    const mouseY = event.clientY;
    const scrollSpeed = 15;
    const activationArea = 200;

    if (mouseY < top + activationArea) {
        clearInterval(autoScrollInterval);
        autoScrollInterval = setInterval(() => {
            dialogContent.scrollTop -= scrollSpeed;
        }, 20);

        return;
    }

    if (mouseY > bottom - activationArea) {
        clearInterval(autoScrollInterval);
        autoScrollInterval = setInterval(() => {
            dialogContent.scrollTop += scrollSpeed;
        }, 20);

        return;
    }

    clearInterval(autoScrollInterval);
}

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog
        v-model:visible="open"
        :style="{ width: '100rem' }"
        class="p-fluid"
        :modal="true"
        header="Adicionar pergunta condicionais"
        :closable="false"
    >
        <div ref="scrollContainer" @dragover="handleAutoScroll">
            <Accordion class="w-full mb-5">
                <AccordionTab
                    v-for="(opcao, indexOpcaoResposta) in _perguntaPrincipal.opcoesRespostas"
                    :key="indexOpcaoResposta"
                    :pt="{
                        headerAction: {
                            style: ['background-color:#FFFFFF']
                        }
                    }"
                >
                    <template #header>
                        <div class="flex flex-row align-items-center gap-3 w-full">
                            <component :is="obterComponenteOpcao(_perguntaPrincipal)" disabled />
                            <h5 class="m-0">{{ opcao.resposta }}</h5>
                            <Button
                                icon="pi pi-plus"
                                label="Condicional"
                                class="p-button-text w-9rem"
                                @click.stop="() => addCondicionalNaAlternativa(indexOpcaoResposta)"
                            />
                        </div>
                    </template>
                    <div v-if="opcao.perguntasCondicionais.length">
                        <div v-for="(perguntaCondicional, indexPerguntaCondicional) in opcao.perguntasCondicionais" :key="indexPerguntaCondicional">
                            <div
                                class="draggable-content"
                                :draggable="true"
                                @dragstart="(event) => dragStart(indexPerguntaCondicional, event)"
                                @dragover.prevent
                                @dragend="dragEnd"
                                @drop="(event) => dragFinish(indexPerguntaCondicional, event, opcao.perguntasCondicionais)"
                            >
                                <ComponentesPerguntas
                                    :perguntaCondicional="perguntaCondicional"
                                    :indexPerguntaCondicional="indexPerguntaCondicional"
                                    :indexOpcaoResposta="indexOpcaoResposta"
                                    :perguntas="props.perguntas"
                                    :contextoGeral="opcao.perguntasCondicionais"
                                    @removerPerguntaCondicional="removerPerguntaCondicional"
                                    @update:perguntaCondicional="(newValue) => (opcao.perguntasCondicionais[indexPerguntaCondicional] = newValue)"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">Nenhuma pergunta condicional adicionada</div>
                </AccordionTab>
            </Accordion>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-text" @click="closeDialog" />
        </template>
    </Dialog>
</template>
<style scoped lang="scss">
.draggable-content {
    &:hover {
        cursor: grab;
    }
    &:active {
        cursor: grabbing;
    }
}
.selects {
    width: 100%;
    border-radius: 6px;
}
</style>
